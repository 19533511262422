@charset "UTF-8";
/* =========================================================
	Reset
========================================================= */
abbr, address, article, aside, audio, b, blockquote, body, button, caption, cite, code, dd, del, dfn, dialog, div, dl, dt, em, fieldset, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 1em; }

html {
  font-size: 62.5%; }

h1, h2, h3, h4, h5, h6 {
  font-weight: bold; }

input, option, select, table, textarea {
  line-height: 1.1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

fieldset {
  min-width: 100%;
  width: 100%;
  display: table; }

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }

:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

img {
  vertical-align: top;
  max-width: 100%;
  height: auto; }

*, *:before, *:after {
  box-sizing: border-box; }

/* =========================================================
	Base Setting
========================================================= */
body, button, h1, h2, h3, h4, h5, h6, input, option, select, table, textarea {
  color: #222;
  font-family: "Montserrat","游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  line-height: 1.1; }

body {
  font-size: 1rem; }

a:link, a:visited {
  color: #000;
  text-decoration: underline; }

a:hover, a:active {
  color: #000;
  text-decoration: none; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

@media (min-width: 768px) {
  .sp-only {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .pc-only {
    display: none !important; } }

/* =========================================================
	Common Layout
========================================================= */
#wrap {
  width: 100%;
  min-width: 1366px;
  padding-top: 100px;
  background: #222;
  overflow: hidden; }
  #wrap.menuActive {
    position: fixed;
    left: 0;
    z-index: 1; }

@media screen and (max-width: 767px) {
  #wrap {
    min-width: auto;
    padding-top: 0;
    background: none; }
  .bg-active #conts .summarySec::after {
    content: "";
    display: block;
    width: 100%;
    height: 100vh;
    background: url(../img/bg_summary02_sp.jpg) no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    z-index: -1; } }

/* Header
--------------------------------------------------------- */
#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; }
  #header.menuActive header {
    padding-right: 17px; }
  #header header {
    display: flex;
    width: 1366px;
    height: 100px;
    margin: 0 auto;
    box-sizing: content-box; }
    #header header .logo {
      padding: 15px 0 15px 30px; }
    #header header .menu {
      margin-left: auto;
      padding: 45px 40px 0 0; }
      #header header .menu i {
        display: block;
        width: 72px;
        height: 15px;
        cursor: pointer;
        position: relative; }
        #header header .menu i::before, #header header .menu i::after {
          content: "";
          display: block;
          width: 100%;
          height: 3px;
          background: #a68d70;
          position: absolute;
          transform: rotate(0deg);
          transition: all .3s ease; }
        #header header .menu i::after {
          transform: translateY(12px); }
      #header header .menu.open i::before, #header header .menu.open i::after {
        background: #fff; }
      #header header .menu.open i::before {
        transform: rotate(-25deg) translateY(0px); }
      #header header .menu.open i::after {
        transform: rotate(25deg) translateY(0px); }

@media screen and (max-width: 767px) {
  #header.menuActive header {
    padding-right: 0; }
  #header header {
    width: auto;
    height: 55px; }
    #header header .logo {
      padding: 10px 0 10px 10px; }
      #header header .logo img {
        width: 196px; }
    #header header .menu {
      padding: 22px 20px 0 0; }
      #header header .menu i {
        width: 30px;
        height: 10px; }
        #header header .menu i::before, #header header .menu i::after {
          height: 2px; }
        #header header .menu i::after {
          transform: translateY(8px); } }

/* Slide Menu
--------------------------------------------------------- */
#slideMenu {
  width: 100%;
  min-width: 1366px;
  height: 100%;
  position: relative;
  overflow: hidden; }
  #slideMenu.active {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000; }
    #slideMenu.active .menuWrap .menuOuter .cvBanner {
      display: block; }
  #slideMenu .menuWrap {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1000; }
    #slideMenu .menuWrap .menuOuter {
      width: 100%;
      height: 100%;
      background: url(../img/bg_navi01_pc.png) no-repeat 12px bottom, url(../img/bg_navi02_pc.jpg) no-repeat center top, url(../img/bg_navi03_pc.jpg) no-repeat center bottom;
      background-color: #0098df;
      position: absolute;
      top: 0;
      right: 0; }
      #slideMenu .menuWrap .menuOuter .menuNav {
        width: 1000px;
        margin: 0 auto; }
        #slideMenu .menuWrap .menuOuter .menuNav ul {
          width: 400px;
          margin-left: auto;
          padding-top: 135px; }
          #slideMenu .menuWrap .menuOuter .menuNav ul li {
            border-bottom: 2px solid #fff; }
            #slideMenu .menuWrap .menuOuter .menuNav ul li a {
              display: block;
              padding: 10px 15px 15px;
              color: #222;
              font-size: 2.6rem;
              font-weight: bold;
              text-decoration: none; }
              #slideMenu .menuWrap .menuOuter .menuNav ul li a .en {
                display: inline-block;
                margin-bottom: 4px;
                color: #fff;
                font-size: 1.6rem; }
      #slideMenu .menuWrap .menuOuter .cvBanner {
        display: none;
        position: fixed;
        top: 55%;
        right: 0;
        z-index: 100; }
        #slideMenu .menuWrap .menuOuter .cvBanner a {
          display: inline-block;
          padding: 30px 14px;
          background: #006ddb;
          color: #fff;
          font-size: 2.4rem;
          font-weight: bold;
          text-decoration: none;
          writing-mode: vertical-rl;
          text-orientation: upright;
          transition: all .3s ease; }
          #slideMenu .menuWrap .menuOuter .cvBanner a:hover {
            background: #a68d70; }
          #slideMenu .menuWrap .menuOuter .cvBanner a::before {
            content: "";
            display: inline-block;
            width: 73px;
            height: 51px;
            margin-bottom: 20px;
            background: url(../img/icon_cv01_pc.png) no-repeat;
            vertical-align: middle; }
          #slideMenu .menuWrap .menuOuter .cvBanner a span {
            vertical-align: middle; }

@media screen and (max-width: 767px) {
  #slideMenu {
    min-width: auto; }
    #slideMenu .menuWrap .menuOuter {
      background-image: none; }
      #slideMenu .menuWrap .menuOuter .menuInner {
        height: 100%;
        margin-top: 70px;
        padding-bottom: 70px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; }
        #slideMenu .menuWrap .menuOuter .menuInner .menuNav {
          width: auto; }
          #slideMenu .menuWrap .menuOuter .menuInner .menuNav ul {
            width: auto;
            padding: 0 40px; }
            #slideMenu .menuWrap .menuOuter .menuInner .menuNav ul li {
              border-width: 1px; }
              #slideMenu .menuWrap .menuOuter .menuInner .menuNav ul li a {
                padding: 10px 0;
                font-size: 2.0rem; }
                #slideMenu .menuWrap .menuOuter .menuInner .menuNav ul li a .en {
                  margin-bottom: 6px;
                  font-size: 1.3rem; }
        #slideMenu .menuWrap .menuOuter .menuInner .inquiryList {
          margin-top: 30px;
          padding: 0 40px 20px; }
          #slideMenu .menuWrap .menuOuter .menuInner .inquiryList li a {
            display: block;
            padding: 20px 0;
            background: #006ddb;
            border-radius: 50px;
            color: #fff;
            font-size: 1.6rem;
            font-weight: bold;
            text-align: center;
            text-decoration: none; }
          #slideMenu .menuWrap .menuOuter .menuInner .inquiryList li + li {
            margin-top: 10px; }
            #slideMenu .menuWrap .menuOuter .menuInner .inquiryList li + li a {
              padding: 16px 0;
              font-size: 2.0rem; }
              #slideMenu .menuWrap .menuOuter .menuInner .inquiryList li + li a::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 26px;
                margin-right: 5px;
                background: url(../img/icon_navi01_sp.png) no-repeat;
                background-size: 24px 26px;
                vertical-align: middle; } }

/* Main Visual
--------------------------------------------------------- */
#mainVisual {
  background: url(../img/bg_mainvisual01_pc.png) no-repeat center bottom; }
  #mainVisual .inner {
    margin: 0 auto;
    padding: 340px 0 170px;
    position: relative; }
    #mainVisual .inner .ttl {
      display: inline-block;
      padding-bottom: 4px;
      color: #FFF;
      font-size: 5.8rem;
      font-weight: bold;
      line-height: 1.6;
      position: absolute;
      top: 115px;
      left: 20%; }
      #mainVisual .inner .ttl::after {
        content: "";
        display: block;
        width: 0;
        height: 4px;
        background: #0098df;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: width 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.8s; }
      #mainVisual .inner .ttl.active::after {
        width: 100%; }
      #mainVisual .inner .ttl.locked::after {
        width: 100%;
        transition: none; }
    #mainVisual .inner .catch {
      padding-top: 250px;
      color: #FFF;
      font-size: 4.0rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.1; }
      #mainVisual .inner .catch .en {
        font-size: 2.2rem;
        font-weight: normal; }

#description {
  width: 1366px;
  margin: 0 auto;
  padding: 95px 180px 70px; }
  #description p {
    color: #FFF;
    font-size: 3.8rem;
    font-weight: bold;
    line-height: 1.8; }
  #description .logo {
    margin-top: -60px;
    text-align: center; }

@media (min-width: 768px) {
  #wrap.menuActive #mainVisual {
    margin-right: 17px; } }

@media screen and (max-width: 767px) {
  #mainVisual {
    padding-top: 55px;
    background-image: url(../img/bg_mainvisual01_sp.png);
    background-color: #222;
    background-size: contain; }
    #mainVisual .inner {
      padding: 62vw 0 180px; }
      #mainVisual .inner .ttl {
        margin: 0;
        font-size: 3.0rem;
        letter-spacing: 5px;
        top: 14%;
        left: 40px; }
      #mainVisual .inner .catch {
        padding-top: 165px;
        font-size: 5.4vw; }
        #mainVisual .inner .catch .en {
          display: inline-block;
          margin-top: 15px;
          font-size: 1.1rem; }
  #description {
    width: auto;
    padding: 45px 0 60px;
    background-color: #222; }
    #description p {
      padding: 0 30px;
      font-size: 2.2rem;
      line-height: 1.7; }
    #description .logo {
      margin-top: -40px;
      padding: 0 13px; } }

/* Contents
--------------------------------------------------------- */
#conts .inner {
  width: 1000px;
  margin: 0 auto; }

@media screen and (max-width: 767px) {
  #conts .inner {
    width: auto; } }

/* CV Banner
--------------------------------------------------------- */
#cvBanner {
  position: fixed;
  top: 55%;
  right: 0;
  z-index: 100; }
  #cvBanner a {
    display: inline-block;
    padding: 30px 14px;
    background: #006ddb;
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    text-decoration: none;
    writing-mode: vertical-rl;
    text-orientation: upright;
    transition: all .3s ease; }
    #cvBanner a:hover {
      background: #a68d70; }
    #cvBanner a::before {
      content: "";
      display: inline-block;
      width: 73px;
      height: 51px;
      margin-bottom: 20px;
      background: url(../img/icon_cv01_pc.png) no-repeat;
      vertical-align: middle; }
    #cvBanner a span {
      vertical-align: middle; }

#cvBox {
  display: none; }

@media screen and (max-width: 767px) {
  #cvBanner {
    display: none; }
  #cvBox {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    transition: all .3s ease;
    z-index: 100; }
    #cvBox ul {
      display: flex; }
      #cvBox ul li {
        width: 50%; }
        #cvBox ul li a {
          display: block;
          width: 100%;
          color: #fff;
          font-size: 3.8vw;
          font-weight: bold;
          text-decoration: none;
          text-align: center; }
          #cvBox ul li a::before {
            content: "";
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle; }
          #cvBox ul li a span {
            vertical-align: middle; }
        #cvBox ul li:nth-child(1) {
          background: #a68d70; }
          #cvBox ul li:nth-child(1) a {
            padding: 15px 10px; }
            #cvBox ul li:nth-child(1) a::before {
              width: 24px;
              height: 26px;
              background: url(../img/icon_cv01_sp.png) no-repeat;
              background-size: 24px 26px; }
        #cvBox ul li:nth-child(2) {
          background: #006ddb; }
          #cvBox ul li:nth-child(2) a {
            padding: 13px 10px; }
            #cvBox ul li:nth-child(2) a::before {
              width: 18px;
              height: 30px;
              background: url(../img/icon_cv02_sp.png) no-repeat;
              background-size: 18px 30px; } }

/* Footer
--------------------------------------------------------- */
footer {
  width: 1366px;
  margin: 0 auto;
  padding: 78px 0 70px; }
  footer .logo {
    text-align: center; }
  footer .copyRight {
    margin-top: 40px;
    color: #FFF;
    font-size: 1.3rem;
    text-align: center; }

@media screen and (max-width: 767px) {
  footer {
    width: auto;
    padding: 50px 0 25px;
    background-color: #222; }
    footer .logo img {
      width: 261px; }
    footer .copyRight {
      margin-top: 25px;
      font-size: 1.0rem; } }

/* =========================================================
	Common Style
========================================================= */
#conts {
  /* Common Title */
  /* Contact Button */ }
  #conts .cmnTtl01 {
    padding-bottom: 32px;
    text-align: center;
    position: relative; }
    #conts .cmnTtl01::after {
      content: "";
      display: block;
      width: 80px;
      height: 4px;
      background: #a68d70;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -40px; }
  #conts .contactBtn {
    text-align: center; }
    #conts .contactBtn a {
      display: inline-block;
      width: 480px;
      padding: 35px 0 30px;
      background: #006ddb;
      border-radius: 50px;
      color: #FFF;
      font-size: 2.4rem;
      font-weight: bold;
      text-decoration: none; }
    #conts .contactBtn .finish {
      display: inline-block;
      margin-bottom: -5px;
      color: #006ddb;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1.9; }
      #conts .contactBtn .finish::before, #conts .contactBtn .finish::after {
        display: inline-block;
        font-size: 4.0rem;
        vertical-align: sub; }
      #conts .contactBtn .finish::before {
        content: "＼";
        margin-right: 10px;
        transform: rotateY(40deg); }
      #conts .contactBtn .finish::after {
        content: "／";
        margin-left: 10px;
        transform: rotateY(-40deg); }

@media (min-width: 768px) {
  #conts .contactBtn a {
    transition: all .3s ease; }
    #conts .contactBtn a:hover {
      background: #a68d70; } }

@media screen and (max-width: 767px) {
  #conts {
    /* Contact Button */ }
    #conts .cmnTtl01 {
      padding-bottom: 20px; }
      #conts .cmnTtl01::after {
        width: 60px;
        height: 3px;
        margin-left: -30px; }
    #conts .contactBtn {
      padding: 0 20px; }
      #conts .contactBtn a {
        width: 100%;
        padding: 20px 0;
        font-size: 5.1vw; }
      #conts .contactBtn .finish {
        font-size: 2.0rem; }
        #conts .contactBtn .finish::before, #conts .contactBtn .finish::after {
          font-size: 3.0rem; }
        #conts .contactBtn .finish::before {
          margin-right: 5px; }
        #conts .contactBtn .finish::after {
          margin-left: 5px; } }

/* Before After Slider
--------------------------------------------------------- */
.ba-slider {
  position: relative;
  overflow: hidden; }
  .ba-slider img {
    display: block;
    width: 100%;
    max-width: none; }
  .ba-slider .resize {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    overflow: hidden; }
  .ba-slider .handle {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 4px;
    margin-left: -2px;
    background: #222222;
    cursor: pointer; }
    .ba-slider .handle::after {
      content: "";
      width: 92px;
      height: 92px;
      margin: -44px 0 0 -44px;
      background: url(../img/icon_handle.png) no-repeat;
      background-size: 92px 92px;
      position: absolute;
      top: 50%; }

@media screen and (max-width: 767px) {
  .ba-slider .handle {
    width: 3px;
    margin-left: -1.5px; }
    .ba-slider .handle::after {
      width: 46px;
      height: 46px;
      margin: -23px 0 0 -23px;
      background-size: 46px 46px; } }

/* Animation
--------------------------------------------------------- */
[data-animate="fadeInUp"],
[data-animate="fadeInTtl"] {
  opacity: 0; }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 2s !important; }

.fadeInUp2 {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  transition: all .8s ease; }
  .fadeInUp2.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeInTtl {
  from {
    transform: translateY(100%);
    opacity: 1; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInTtl {
  from {
    transform: translateY(100%);
    opacity: 1; }
  to {
    transform: translateY(0);
    opacity: 1; } }

.fadeInTtl p {
  overflow: hidden; }
  .fadeInTtl p span {
    display: inline-block; }

.fadeInTtl.active p span {
  transform: translateY(100%);
  animation-name: fadeInTtl;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-duration: .8s;
  animation-delay: 0s;
  animation-fill-mode: forwards; }

.fadeInTtl.active p:nth-child(1) span:nth-child(1) {
  animation-delay: 0.5s; }

.fadeInTtl.active p:nth-child(1) span:nth-child(2) {
  animation-delay: 0.55s; }

.fadeInTtl.active p:nth-child(1) span:nth-child(3) {
  animation-delay: 0.6s; }

.fadeInTtl.active p:nth-child(1) span:nth-child(4) {
  animation-delay: 0.65s; }

.fadeInTtl.active p:nth-child(2) span:nth-child(1) {
  animation-delay: 0.7s; }

.fadeInTtl.active p:nth-child(2) span:nth-child(2) {
  animation-delay: 0.75s; }

.fadeInTtl.active p:nth-child(2) span:nth-child(3) {
  animation-delay: 0.8s; }

.fadeInTtl.active p:nth-child(2) span:nth-child(4) {
  animation-delay: 0.85s; }

.fadeInTtl.active p:nth-child(2) span:nth-child(5) {
  animation-delay: 0.9s; }

.fadeInTtl.active p:nth-child(3) span:nth-child(1) {
  animation-delay: 0.95s; }

.fadeInTtl.active p:nth-child(3) span:nth-child(2) {
  animation-delay: 1.0s; }

.fadeInTtl.active p:nth-child(3) span:nth-child(3) {
  animation-delay: 1.05s; }

.fadeInTtl.active p:nth-child(3) span:nth-child(4) {
  animation-delay: 1.1s; }

.fadeInTtl.active p:nth-child(3) span:nth-child(5) {
  animation-delay: 1.15s; }

/* =========================================================
	Contents
========================================================= */
#conts .summarySec {
  background: #e9e9e9 url(../img/bg_summary02_pc.jpg) no-repeat fixed 50% bottom;
  background-size: cover;
  position: relative; }
  #conts .summarySec::before {
    content: "";
    display: block;
    width: 662px;
    height: 228px;
    background: url(../img/bg_section01.png) no-repeat;
    position: absolute;
    top: -2px;
    left: 0;
    z-index: 10; }
  #conts .summarySec > .inner {
    padding-top: 205px; }
  #conts .summarySec h2 {
    color: #a68d70;
    font-size: 4.0rem;
    font-weight: bold;
    text-align: center; }
  #conts .summarySec p {
    font-size: 1.8rem;
    line-height: 1.9; }
    #conts .summarySec p + p {
      margin-top: 35px; }
    #conts .summarySec p .blue {
      color: #0098df;
      font-weight: bold; }
  #conts .summarySec .leadBox {
    margin-top: 30px;
    padding: 0 100px; }
  #conts .summarySec .difficultSec {
    margin-top: 85px;
    padding: 100px 100px 75px;
    background: #d2d2d2;
    position: relative; }
    #conts .summarySec .difficultSec::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 60px solid transparent;
      border-top: 60px solid #d2d2d2;
      position: absolute;
      left: 50%;
      bottom: -120px;
      margin-left: -60px;
      z-index: 99; }
    #conts .summarySec .difficultSec h3 {
      font-size: 4.0rem;
      font-weight: bold;
      text-align: center; }
      #conts .summarySec .difficultSec h3 + p {
        margin-top: 45px; }
    #conts .summarySec .difficultSec p {
      font-size: 1.6rem; }
    #conts .summarySec .difficultSec .flexBox {
      display: flex;
      margin-top: 25px; }
      #conts .summarySec .difficultSec .flexBox .imgBox {
        width: 262px; }
      #conts .summarySec .difficultSec .flexBox .textBox {
        width: 470px;
        margin-left: auto;
        padding-top: 10px; }
      #conts .summarySec .difficultSec .flexBox + p {
        margin-top: 30px; }
  #conts .summarySec .continueSec {
    margin-top: 125px;
    padding: 0 100px; }
    #conts .summarySec .continueSec p {
      font-size: 1.6rem; }
    #conts .summarySec .continueSec .ttlBox {
      text-align: center; }
      #conts .summarySec .continueSec .ttlBox h3 {
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 2px solid #a68d70;
        font-size: 4.0rem;
        font-weight: bold; }
      #conts .summarySec .continueSec .ttlBox + p {
        margin-top: 50px; }
  #conts .summarySec .catchBox {
    margin-top: 70px;
    background: #222222 url(../img/bg_catch01_pc.png) no-repeat center;
    background-size: cover;
    text-align: center; }
    #conts .summarySec .catchBox p {
      display: inline-block;
      padding: 30px 110px 25px 145px;
      color: #a68d70;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1;
      position: relative; }
      #conts .summarySec .catchBox p::before, #conts .summarySec .catchBox p::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0; }
      #conts .summarySec .catchBox p::before {
        width: 116px;
        height: 162px;
        background: url(../img/icon_summary01_pc.png) no-repeat;
        left: 0; }
      #conts .summarySec .catchBox p::after {
        width: 84px;
        height: 162px;
        background: url(../img/icon_summary02_pc.png) no-repeat;
        right: 0; }
  #conts .summarySec .contactBtn {
    padding: 55px 0 65px;
    position: relative;
    background: #f7f7f7; }
    #conts .summarySec .contactBtn::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 90px solid transparent;
      border-top: 90px solid #f7f7f7;
      position: absolute;
      left: 50%;
      bottom: -180px;
      margin-left: -90px;
      z-index: 99; }
  #conts .summarySec .bfBox {
    padding: 160px 0;
    background: #222222; }
    #conts .summarySec .bfBox .tabList {
      display: flex;
      width: 500px;
      padding: 0 20px; }
      #conts .summarySec .bfBox .tabList li {
        width: 200px; }
        #conts .summarySec .bfBox .tabList li:last-child {
          margin-left: auto; }
        #conts .summarySec .bfBox .tabList li a {
          display: block;
          padding: 12px 0 8px;
          background: #979797;
          border: 5px solid #979797;
          border-radius: 20px;
          color: #FFF;
          font-size: 3.2rem;
          font-weight: bold;
          text-align: center;
          text-decoration: none;
          line-height: 1; }
        #conts .summarySec .bfBox .tabList li.active a {
          background: #a68d70;
          border-color: #FFF; }
    #conts .summarySec .bfBox .flexBox {
      display: flex;
      align-items: center;
      margin-top: 20px; }
      #conts .summarySec .bfBox .flexBox .slideBox {
        width: 500px; }
      #conts .summarySec .bfBox .flexBox .textBox {
        width: 460px;
        margin-left: auto; }
        #conts .summarySec .bfBox .flexBox .textBox p {
          color: #FFF; }
          #conts .summarySec .bfBox .flexBox .textBox p.ttl {
            font-size: 2.0rem;
            font-weight: bold; }
        #conts .summarySec .bfBox .flexBox .textBox dl {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px; }
          #conts .summarySec .bfBox .flexBox .textBox dl dt, #conts .summarySec .bfBox .flexBox .textBox dl dd {
            color: #FFF;
            font-size: 1.6rem;
            line-height: 2.0; }
          #conts .summarySec .bfBox .flexBox .textBox dl dt {
            width: 160px; }
          #conts .summarySec .bfBox .flexBox .textBox dl dd {
            width: 300px; }

#conts .menuSec {
  padding: 150px 0 100px;
  background: #0098df;
  position: relative; }
  #conts .menuSec::before {
    content: "";
    display: block;
    width: 668px;
    height: 228px;
    background: url(../img/bg_section02.png) no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
  #conts .menuSec .cmnTtl01::after {
    background: #fff; }
  #conts .menuSec p {
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.9; }
    #conts .menuSec p + p {
      margin-top: 30px; }
  #conts .menuSec .catchBox {
    display: flex;
    align-items: center;
    margin-top: 70px; }
    #conts .menuSec .catchBox .imgBox {
      width: 470px; }
    #conts .menuSec .catchBox .textBox {
      width: 470px;
      margin-left: auto;
      padding-bottom: 50px; }
      #conts .menuSec .catchBox .textBox .catch {
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.6; }
  #conts .menuSec .detailSec {
    margin-top: 90px;
    position: relative; }
    #conts .menuSec .detailSec::before {
      content: "";
      display: block;
      width: 100%;
      height: 75vw;
      background: url(../img/bg_menu01_pc.png) no-repeat center top;
      background-size: contain;
      position: absolute;
      top: -140px;
      left: 0; }
    #conts .menuSec .detailSec .inner {
      position: relative;
      z-index: 10; }
    #conts .menuSec .detailSec h3 {
      padding-bottom: 20px;
      border-bottom: 4px solid #ccf11f; }
    #conts .menuSec .detailSec .subTtl {
      margin-top: 20px;
      color: #fff;
      font-size: 3.4rem;
      font-weight: bold;
      line-height: 1.0; }
    #conts .menuSec .detailSec .flexBox {
      display: flex; }
      #conts .menuSec .detailSec .flexBox .imgBox {
        text-align: center; }
      #conts .menuSec .detailSec .flexBox .textBox {
        width: 480px;
        margin-left: auto; }
    #conts .menuSec .detailSec .catchText {
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center; }
    #conts .menuSec .detailSec:nth-of-type(1) .flexBox {
      margin-top: 65px; }
      #conts .menuSec .detailSec:nth-of-type(1) .flexBox .imgBox {
        width: 385px;
        margin-left: 32px; }
      #conts .menuSec .detailSec:nth-of-type(1) .flexBox .textBox {
        padding-top: 15px; }
    #conts .menuSec .detailSec:nth-of-type(1) .catchText {
      margin-top: 85px; }
    #conts .menuSec .detailSec:nth-of-type(2) .flexBox {
      margin-top: 2px; }
      #conts .menuSec .detailSec:nth-of-type(2) .flexBox .imgBox {
        order: 2;
        width: 381px;
        margin-right: 42px; }
      #conts .menuSec .detailSec:nth-of-type(2) .flexBox .textBox {
        order: 1;
        padding-top: 85px;
        margin: 0 auto 0 0; }
    #conts .menuSec .detailSec:nth-of-type(2) .catchText {
      margin-top: 60px; }
    #conts .menuSec .detailSec:nth-of-type(3) .flexBox {
      margin-top: 15px; }
      #conts .menuSec .detailSec:nth-of-type(3) .flexBox .imgBox {
        width: 389px;
        margin-left: 54px; }
      #conts .menuSec .detailSec:nth-of-type(3) .flexBox .textBox {
        padding-top: 90px; }
    #conts .menuSec .detailSec:nth-of-type(3) .catchText {
      margin-top: 65px; }
    #conts .menuSec .detailSec .meritSec {
      margin-top: 80px; }
      #conts .menuSec .detailSec .meritSec h4 {
        padding-bottom: 8px;
        border-bottom: 1px solid #fff;
        color: #fff;
        font-size: 4.6rem;
        font-weight: bold; }
      #conts .menuSec .detailSec .meritSec .meritList {
        display: flex;
        margin-top: 35px; }
        #conts .menuSec .detailSec .meritSec .meritList li {
          width: 310px; }
          #conts .menuSec .detailSec .meritSec .meritList li + li {
            margin-left: 35px; }
      #conts .menuSec .detailSec .meritSec .riskSec {
        margin-top: 40px;
        padding: 30px 30px 20px;
        border: 8px solid #fff; }
        #conts .menuSec .detailSec .meritSec .riskSec h5 {
          color: #fff;
          font-size: 2.0rem;
          font-weight: bold; }
        #conts .menuSec .detailSec .meritSec .riskSec p {
          margin-top: 10px; }
    #conts .menuSec .detailSec .linkBtn {
      margin-top: 40px;
      text-align: center; }
      #conts .menuSec .detailSec .linkBtn a {
        display: inline-block;
        width: 154px;
        padding: 63px 0 59px;
        background: #45afe0;
        border: 4px solid #fff;
        border-radius: 100px;
        color: #fff;
        font-size: 2.2rem;
        font-weight: bold;
        text-decoration: none;
        position: relative; }
        #conts .menuSec .detailSec .linkBtn a::after {
          content: "";
          width: 17px;
          height: 17px;
          border-top: solid 4px #fff;
          border-right: solid 4px #fff;
          transform: rotate(135deg);
          position: absolute;
          bottom: 18px;
          left: 50%;
          margin-left: -8px; }
  #conts .menuSec .contactBtn {
    margin-top: 85px; }
    #conts .menuSec .contactBtn .finish {
      color: #fff; }
    #conts .menuSec .contactBtn a {
      position: relative; }
      #conts .menuSec .contactBtn a::after {
        content: "";
        display: block;
        width: 127px;
        height: 184px;
        background: url(../img/icon_menu01_pc.png) no-repeat;
        position: absolute;
        bottom: 0;
        right: 10px; }
      #conts .menuSec .contactBtn a span {
        position: relative;
        z-index: 10; }

#conts .priceSec {
  padding: 180px 0 90px;
  background: #f7f7f7;
  position: relative; }
  #conts .priceSec::before {
    content: "";
    display: block;
    width: 804px;
    height: 282px;
    background: url(../img/bg_section03.png) no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
  #conts .priceSec .leadBox {
    margin-top: 70px; }
    #conts .priceSec .leadBox p {
      font-size: 1.8rem;
      line-height: 1.9; }
      #conts .priceSec .leadBox p + p {
        margin-top: 35px; }
  #conts .priceSec .priceList {
    display: flex;
    margin-top: 55px; }
    #conts .priceSec .priceList li {
      width: 310px;
      padding: 40px 0 45px;
      border-radius: 30px;
      position: relative; }
      #conts .priceSec .priceList li::after {
        content: "";
        display: block;
        position: absolute; }
      #conts .priceSec .priceList li:nth-child(1) {
        background: #df6352; }
        #conts .priceSec .priceList li:nth-child(1)::after {
          width: 149px;
          height: 132px;
          background: url(../img/bg_price01_pc.png) no-repeat;
          bottom: -19px;
          right: -12px; }
      #conts .priceSec .priceList li:nth-child(2) {
        background: #9dc93e; }
        #conts .priceSec .priceList li:nth-child(2)::after {
          width: 92px;
          height: 146px;
          background: url(../img/bg_price02_pc.png) no-repeat;
          bottom: -27px;
          right: -10px; }
      #conts .priceSec .priceList li:nth-child(3) {
        background: #efc866; }
        #conts .priceSec .priceList li:nth-child(3)::after {
          width: 76px;
          height: 151px;
          background: url(../img/bg_price03_pc.png) no-repeat;
          bottom: -22px;
          right: -24px; }
      #conts .priceSec .priceList li + li {
        margin-left: 35px; }
      #conts .priceSec .priceList li .priceWrap {
        position: relative;
        z-index: 10; }
      #conts .priceSec .priceList li p {
        color: #fff;
        font-weight: bold;
        text-align: center; }
      #conts .priceSec .priceList li .ttl {
        padding-bottom: 8px;
        font-size: 2.6rem;
        position: relative; }
        #conts .priceSec .priceList li .ttl::after {
          content: "";
          display: block;
          width: 200px;
          height: 2px;
          background: #fff;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -100px; }
      #conts .priceSec .priceList li .period {
        margin-top: 15px;
        font-size: 2.0rem; }
      #conts .priceSec .priceList li .price {
        margin-top: 10px;
        font-size: 3.2rem; }
        #conts .priceSec .priceList li .price .yen {
          font-size: 2.7rem; }
      #conts .priceSec .priceList li .limited {
        margin-top: 20px;
        font-size: 2.7rem; }
      #conts .priceSec .priceList li .att {
        margin-top: 12px;
        font-size: 1.6rem; }
  #conts .priceSec .courseSec {
    margin-top: 130px; }
    #conts .priceSec .courseSec h3 {
      padding: 0 6px 2px;
      font-size: 4.6rem;
      font-weight: bold; }
    #conts .priceSec .courseSec section {
      margin-top: 20px;
      padding-top: 22px;
      position: relative; }
      #conts .priceSec .courseSec section::before {
        content: "";
        display: block;
        width: 440px;
        height: 5px;
        background: #000;
        position: absolute;
        top: 0;
        left: 0; }
      #conts .priceSec .courseSec section + section {
        margin-top: 60px; }
      #conts .priceSec .courseSec section h4 {
        font-size: 2.4rem;
        font-weight: bold; }
      #conts .priceSec .courseSec section .flexBox {
        display: flex;
        margin-top: 15px; }
        #conts .priceSec .courseSec section .flexBox .imgBox {
          width: 170px; }
        #conts .priceSec .courseSec section .flexBox .pcTable {
          width: 830px;
          border-collapse: collapse; }
          #conts .priceSec .courseSec section .flexBox .pcTable th, #conts .priceSec .courseSec section .flexBox .pcTable td {
            border: 3px solid #0098df;
            font-size: 1.8rem;
            font-weight: bold;
            text-align: center;
            vertical-align: middle; }
          #conts .priceSec .courseSec section .flexBox .pcTable th {
            height: 58px;
            background: #e5e5e5;
            border-left: none;
            border-right: none; }
          #conts .priceSec .courseSec section .flexBox .pcTable td {
            width: 215px;
            background: #fff; }
            #conts .priceSec .courseSec section .flexBox .pcTable td .price {
              font-size: 2.8rem; }
            #conts .priceSec .courseSec section .flexBox .pcTable td .yen {
              font-size: 2.1rem; }
          #conts .priceSec .courseSec section .flexBox .pcTable th + td {
            border-left: none; }
          #conts .priceSec .courseSec section .flexBox .pcTable tr:nth-child(2) th {
            height: 59px; }
          #conts .priceSec .courseSec section .flexBox .pcTable.coolTable td {
            width: 129px; }
            #conts .priceSec .courseSec section .flexBox .pcTable.coolTable td .price {
              font-size: 2.3rem; }
            #conts .priceSec .courseSec section .flexBox .pcTable.coolTable td .yen {
              font-size: 1.7rem; }
    #conts .priceSec .courseSec .attList {
      margin-top: 35px; }
      #conts .priceSec .courseSec .attList li {
        margin-left: 1em;
        text-indent: -1em;
        font-size: 1.4rem;
        line-height: 1.8; }
    #conts .priceSec .courseSec .cardBox {
      margin-top: 65px;
      text-align: center; }
  #conts .priceSec .contactBtn {
    margin-top: 115px; }
    #conts .priceSec .contactBtn a {
      position: relative; }
      #conts .priceSec .contactBtn a::after {
        content: "";
        display: block;
        width: 115px;
        height: 169px;
        background: url(../img/icon_price01_pc.png) no-repeat;
        position: absolute;
        bottom: 0;
        left: 20px; }
      #conts .priceSec .contactBtn a span {
        position: relative;
        z-index: 10; }

#conts .clinicSec {
  padding: 130px 0 85px;
  background: url(../img/bg_clinic03_pc.jpg) no-repeat left 1020px, url(../img/bg_clinic04_pc.jpg) repeat-x left 1020px, linear-gradient(to bottom, #222, #222 50%, #f7f7f7 50%, #f7f7f7 100%);
  position: relative; }
  #conts .clinicSec::before {
    content: "";
    display: block;
    width: 624px;
    height: 192px;
    background: url(../img/bg_section04.png) no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
  #conts .clinicSec::after {
    content: "";
    display: block;
    width: 100%;
    height: 95vw;
    background: url(../img/bg_clinic02_pc.png) no-repeat center top;
    background-size: contain;
    position: absolute;
    top: 135px;
    left: 0; }
  #conts .clinicSec .clinicWrap {
    position: relative;
    z-index: 10; }
  #conts .clinicSec .ttlBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    margin-top: 80px;
    background: url(../img/bg_clinic01_pc.jpg) no-repeat;
    background-size: cover; }
  #conts .clinicSec .flexBox {
    display: flex;
    margin-top: 80px; }
    #conts .clinicSec .flexBox .textBox {
      order: 2;
      width: 480px;
      margin-left: auto;
      padding-top: 65px; }
      #conts .clinicSec .flexBox .textBox dl dt {
        padding-bottom: 5px;
        border-bottom: 2px solid #a68d70;
        color: #a68d70;
        font-size: 2.4rem;
        font-weight: bold; }
      #conts .clinicSec .flexBox .textBox dl dd {
        padding: 20px 3px 45px;
        color: #fff;
        font-size: 1.8rem; }
    #conts .clinicSec .flexBox .mapBox {
      order: 1;
      width: 480px; }
  #conts .clinicSec .messageBox {
    margin-top: 120px; }
    #conts .clinicSec .messageBox p {
      color: #fff;
      font-size: 2.4rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.9; }
      #conts .clinicSec .messageBox p + p {
        margin-top: 50px; }
      #conts .clinicSec .messageBox p:nth-child(n + 4) {
        color: #231815;
        text-shadow: 10px 10px 20px rgba(227, 227, 227, 0.9), -10px -10px 20px rgba(227, 227, 227, 0.9); }
  #conts .clinicSec .logo {
    margin-top: -65px;
    text-align: center; }
  #conts .clinicSec .contactBtn {
    margin-top: -27px; }
    #conts .clinicSec .contactBtn .imgBox {
      padding-right: 25px; }

@media (min-width: 768px) {
  #conts .menuSec .detailSec .linkBtn a {
    transition: all .3s ease; }
    #conts .menuSec .detailSec .linkBtn a:hover {
      opacity: .7; } }

@media screen and (max-width: 767px) {
  #conts .summarySec {
    background-image: none;
    background-color: transparent; }
    #conts .summarySec::before {
      width: 300px;
      height: 103px;
      background-size: 300px 103px;
      top: 0; }
    #conts .summarySec > .inner {
      padding-top: 140px; }
    #conts .summarySec h2 {
      font-size: 7vw; }
    #conts .summarySec p {
      font-size: 1.4rem; }
      #conts .summarySec p + p {
        margin-top: 25px; }
    #conts .summarySec .leadBox {
      margin-top: 25px;
      padding: 0 15px; }
    #conts .summarySec .difficultSec {
      margin-top: 60px;
      padding: 50px 15px; }
      #conts .summarySec .difficultSec::after {
        border-width: 30px;
        bottom: -60px;
        margin-left: -30px; }
      #conts .summarySec .difficultSec h3 {
        font-size: 7vw;
        line-height: 1.6; }
        #conts .summarySec .difficultSec h3 + p {
          margin-top: 20px; }
      #conts .summarySec .difficultSec p {
        font-size: 1.4rem; }
      #conts .summarySec .difficultSec .flexBox {
        display: block; }
        #conts .summarySec .difficultSec .flexBox .imgBox {
          width: auto;
          text-align: center; }
          #conts .summarySec .difficultSec .flexBox .imgBox img {
            width: 226px; }
        #conts .summarySec .difficultSec .flexBox .textBox {
          width: auto;
          margin-top: 25px;
          padding-top: 0; }
    #conts .summarySec .continueSec {
      margin-top: 60px;
      padding: 0 15px; }
      #conts .summarySec .continueSec .ttlBox h3 {
        font-size: 6.2vw;
        border-bottom-width: 1px; }
      #conts .summarySec .continueSec .ttlBox + p {
        margin-top: 25px; }
      #conts .summarySec .continueSec p {
        font-size: 1.4rem; }
    #conts .summarySec .catchBox {
      margin-top: 65px;
      background-image: url(../img/bg_catch01_sp.png); }
      #conts .summarySec .catchBox p {
        display: block;
        padding: 16px 0;
        font-size: 6.7vw;
        line-height: 1.4; }
        #conts .summarySec .catchBox p::before, #conts .summarySec .catchBox p::after {
          z-index: 0; }
        #conts .summarySec .catchBox p::before {
          width: 86.5px;
          height: 150.5px;
          background-image: url(../img/icon_summary01_sp.png);
          background-size: 86.5px 150.5px; }
        #conts .summarySec .catchBox p::after {
          width: 80.5px;
          height: 156px;
          background-image: url(../img/icon_summary02_sp.png);
          background-size: 80.5px 156px; }
        #conts .summarySec .catchBox p span {
          position: relative;
          z-index: 10; }
    #conts .summarySec .contactBtn {
      padding: 45px 20px 60px; }
      #conts .summarySec .contactBtn::after {
        border-width: 35px;
        bottom: -70px;
        margin-left: -35px; }
    #conts .summarySec .bfBox {
      padding: 60px 0; }
      #conts .summarySec .bfBox .tabList {
        width: auto; }
        #conts .summarySec .bfBox .tabList li {
          width: 45%; }
          #conts .summarySec .bfBox .tabList li a {
            padding: 10px 0;
            border-width: 3px;
            border-radius: 10px;
            font-size: 6.4vw; }
      #conts .summarySec .bfBox .flexBox {
        display: block;
        margin-top: 30px; }
        #conts .summarySec .bfBox .flexBox .slideBox {
          width: auto; }
        #conts .summarySec .bfBox .flexBox .textBox {
          width: auto;
          margin-top: 25px;
          padding: 0 20px; }
          #conts .summarySec .bfBox .flexBox .textBox p.ttl {
            font-size: 1.6rem; }
          #conts .summarySec .bfBox .flexBox .textBox dl {
            display: block;
            overflow: hidden; }
            #conts .summarySec .bfBox .flexBox .textBox dl dt, #conts .summarySec .bfBox .flexBox .textBox dl dd {
              float: left;
              width: auto;
              font-size: 3.5vw; }
            #conts .summarySec .bfBox .flexBox .textBox dl dt {
              clear: both; }
  #conts .menuSec {
    padding-top: 150px; }
    #conts .menuSec::before {
      width: 302px;
      height: 103px;
      background-size: 302px 103px; }
    #conts .menuSec .cmnTtl01 img {
      width: 105px; }
    #conts .menuSec p {
      font-size: 1.4rem; }
      #conts .menuSec p + p {
        margin-top: 25px; }
    #conts .menuSec .catchBox {
      display: block;
      margin-top: 50px;
      padding: 0 20px; }
      #conts .menuSec .catchBox .imgBox {
        width: auto;
        text-align: center; }
      #conts .menuSec .catchBox .textBox {
        width: auto;
        padding: 35px 0 0; }
        #conts .menuSec .catchBox .textBox .catch {
          font-size: 2.6rem;
          text-align: center;
          line-height: 1.4; }
          #conts .menuSec .catchBox .textBox .catch + p {
            margin-top: 20px; }
    #conts .menuSec .detailSec {
      margin-top: 50px; }
      #conts .menuSec .detailSec::before {
        height: 95vw;
        background-image: url(../img/bg_menu01_sp.png);
        top: 25px; }
      #conts .menuSec .detailSec .inner {
        padding: 0 20px; }
      #conts .menuSec .detailSec h3 {
        padding-bottom: 10px; }
      #conts .menuSec .detailSec .subTtl {
        margin-top: 10px;
        font-size: 1.8rem; }
      #conts .menuSec .detailSec .flexBox {
        display: block; }
        #conts .menuSec .detailSec .flexBox .imgBox {
          width: auto !important;
          text-align: center; }
        #conts .menuSec .detailSec .flexBox .textBox {
          width: auto; }
      #conts .menuSec .detailSec .catchText {
        margin-top: 40px !important;
        font-size: 2.2rem;
        line-height: 1.5; }
      #conts .menuSec .detailSec:nth-of-type(1) h3 img {
        width: 66.7vw; }
      #conts .menuSec .detailSec:nth-of-type(1) .flexBox {
        margin-top: 20px; }
        #conts .menuSec .detailSec:nth-of-type(1) .flexBox .imgBox {
          margin-left: 10px; }
          #conts .menuSec .detailSec:nth-of-type(1) .flexBox .imgBox img {
            width: 269px; }
        #conts .menuSec .detailSec:nth-of-type(1) .flexBox .textBox {
          padding-top: 20px; }
      #conts .menuSec .detailSec:nth-of-type(2) h3 img {
        width: 75.8vw; }
      #conts .menuSec .detailSec:nth-of-type(2) .flexBox {
        margin-top: 30px; }
        #conts .menuSec .detailSec:nth-of-type(2) .flexBox .imgBox {
          margin: 0 0 0 10px; }
          #conts .menuSec .detailSec:nth-of-type(2) .flexBox .imgBox img {
            width: 234px; }
        #conts .menuSec .detailSec:nth-of-type(2) .flexBox .textBox {
          padding-top: 50px; }
      #conts .menuSec .detailSec:nth-of-type(3) h3 img {
        width: 83.5vw; }
      #conts .menuSec .detailSec:nth-of-type(3) .flexBox {
        margin-top: 10px; }
        #conts .menuSec .detailSec:nth-of-type(3) .flexBox .imgBox {
          margin-left: 15px; }
          #conts .menuSec .detailSec:nth-of-type(3) .flexBox .imgBox img {
            width: 251px; }
        #conts .menuSec .detailSec:nth-of-type(3) .flexBox .textBox {
          padding-top: 45px; }
      #conts .menuSec .detailSec .meritSec {
        margin-top: 40px; }
        #conts .menuSec .detailSec .meritSec h4 {
          padding-bottom: 6px;
          font-size: 2.6rem;
          line-height: 1.3; }
        #conts .menuSec .detailSec .meritSec .meritList {
          display: block;
          margin-top: 20px; }
          #conts .menuSec .detailSec .meritSec .meritList li {
            width: auto;
            text-align: center; }
            #conts .menuSec .detailSec .meritSec .meritList li + li {
              margin: 20px 0 0; }
        #conts .menuSec .detailSec .meritSec .riskSec {
          margin-top: 30px;
          padding: 15px 20px;
          border-width: 5px; }
          #conts .menuSec .detailSec .meritSec .riskSec h5 {
            font-size: 1.6rem;
            line-height: 1.6; }
          #conts .menuSec .detailSec .meritSec .riskSec p {
            font-size: 1.2rem; }
      #conts .menuSec .detailSec .linkBtn {
        margin-top: 30px; }
        #conts .menuSec .detailSec .linkBtn a {
          width: 115px;
          padding: 44px 0;
          font-size: 1.8rem; }
          #conts .menuSec .detailSec .linkBtn a::after {
            width: 14px;
            height: 14px;
            border-width: 3px;
            bottom: 20px;
            margin-left: -7px; }
    #conts .menuSec .contactBtn {
      margin-top: 35px; }
      #conts .menuSec .contactBtn a::after {
        width: 70px;
        height: 108px;
        background-image: url(../img/icon_menu01_sp.png);
        background-size: 70px 108px;
        right: 15px; }
  #conts .priceSec {
    padding-top: 130px; }
    #conts .priceSec::before {
      width: 308px;
      height: 103px;
      background-size: 308px 103px; }
    #conts .priceSec .inner {
      padding: 0 20px; }
    #conts .priceSec .cmnTtl01 img {
      width: 106px; }
    #conts .priceSec .leadBox {
      margin-top: 35px; }
      #conts .priceSec .leadBox p {
        font-size: 1.4rem; }
        #conts .priceSec .leadBox p + p {
          margin-top: 25px; }
    #conts .priceSec .priceList {
      display: block;
      margin-top: 30px; }
      #conts .priceSec .priceList li {
        width: auto;
        padding: 40px 0; }
        #conts .priceSec .priceList li:nth-child(1)::after {
          width: 137px;
          height: 124px;
          background-image: url(../img/bg_price01_sp.png);
          background-size: 137px 124px;
          bottom: -2px;
          right: -14px; }
        #conts .priceSec .priceList li:nth-child(2)::after {
          width: 89px;
          height: 140px;
          background-image: url(../img/bg_price02_sp.png);
          background-size: 89px 140px;
          bottom: -14px;
          right: auto;
          left: -6px; }
        #conts .priceSec .priceList li:nth-child(3)::after {
          width: 79px;
          height: 195px;
          background-image: url(../img/bg_price03_sp.png);
          background-size: 79px 195px;
          bottom: -32px;
          right: -2px; }
        #conts .priceSec .priceList li + li {
          margin: 15px 0 0; }
        #conts .priceSec .priceList li .ttl {
          padding-bottom: 10px;
          font-size: 7.5vw; }
          #conts .priceSec .priceList li .ttl::after {
            width: 220px;
            margin-left: -110px; }
        #conts .priceSec .priceList li .period {
          font-size: 5.1vw; }
        #conts .priceSec .priceList li .price {
          font-size: 9.4vw; }
          #conts .priceSec .priceList li .price .yen {
            font-size: 6.4vw; }
        #conts .priceSec .priceList li .limited {
          font-size: 7vw; }
        #conts .priceSec .priceList li .att {
          margin-top: 15px;
          font-size: 4vw; }
    #conts .priceSec .courseSec {
      margin-top: 85px; }
      #conts .priceSec .courseSec h3 {
        padding: 0 0 7px;
        font-size: 2.6rem; }
      #conts .priceSec .courseSec section {
        margin-top: 10px;
        padding-top: 15px; }
        #conts .priceSec .courseSec section::before {
          width: 170px;
          height: 2px; }
        #conts .priceSec .courseSec section + section {
          margin-top: 50px; }
        #conts .priceSec .courseSec section h4 {
          font-size: 1.8rem; }
        #conts .priceSec .courseSec section .flexBox {
          display: block;
          margin-top: 10px; }
          #conts .priceSec .courseSec section .flexBox .imgBox {
            width: auto; }
            #conts .priceSec .courseSec section .flexBox .imgBox picture {
              width: 100%; }
          #conts .priceSec .courseSec section .flexBox .spTable {
            width: 100%;
            border-collapse: collapse; }
            #conts .priceSec .courseSec section .flexBox .spTable th, #conts .priceSec .courseSec section .flexBox .spTable td {
              border: 2px solid #0098df;
              font-weight: bold;
              text-align: center;
              vertical-align: middle; }
            #conts .priceSec .courseSec section .flexBox .spTable th {
              padding: 8px 0;
              background: #e5e5e5;
              border-bottom: none;
              font-size: 1.4rem; }
              #conts .priceSec .courseSec section .flexBox .spTable th:first-child {
                width: 36%; }
            #conts .priceSec .courseSec section .flexBox .spTable td {
              padding: 20px 0;
              background: #fff;
              font-size: 1.5rem; }
              #conts .priceSec .courseSec section .flexBox .spTable td .price {
                font-size: 2.1rem; }
              #conts .priceSec .courseSec section .flexBox .spTable td .yen {
                font-size: 1.6rem; }
            #conts .priceSec .courseSec section .flexBox .spTable tbody tr:nth-child(1) td {
              border-top: none; }
      #conts .priceSec .courseSec .attList {
        margin-top: 25px; }
        #conts .priceSec .courseSec .attList li {
          font-size: 1.2rem;
          line-height: 2.0; }
      #conts .priceSec .courseSec .cardBox {
        margin-top: 20px; }
    #conts .priceSec .contactBtn {
      margin-top: 45px;
      padding: 0; }
      #conts .priceSec .contactBtn a::after {
        width: 72.5px;
        height: 108px;
        background-image: url(../img/icon_price01_sp.png);
        background-size: 72.5px 108px;
        left: 20px; }
  #conts .clinicSec {
    padding: 130px 0 50px;
    background: url(../img/bg_clinic03_sp.jpg) no-repeat left 66%, linear-gradient(to bottom, #222, #222 50%, #f7f7f7 50%, #f7f7f7 100%);
    background-size: contain; }
    #conts .clinicSec::before {
      width: 300px;
      height: 92px;
      background-size: 300px 92px; }
    #conts .clinicSec::after {
      content: none; }
    #conts .clinicSec .cmnTtl01 img {
      width: 118px; }
    #conts .clinicSec .ttlBox {
      height: 225px;
      margin-top: 45px;
      background-image: url(../img/bg_clinic01_sp.png); }
      #conts .clinicSec .ttlBox img {
        width: 222px; }
    #conts .clinicSec .flexBox {
      display: block;
      padding: 0 20px;
      position: relative; }
      #conts .clinicSec .flexBox::before {
        content: "";
        display: block;
        width: 100%;
        height: 125vw;
        background: url(../img/bg_clinic02_sp.png) no-repeat center top;
        background-size: contain;
        position: absolute;
        top: 58vw;
        left: 0; }
      #conts .clinicSec .flexBox .textBox {
        width: auto;
        padding-top: 0; }
        #conts .clinicSec .flexBox .textBox dl dt {
          padding-bottom: 7px;
          border-width: 1px;
          font-size: 2.6rem; }
        #conts .clinicSec .flexBox .textBox dl dd {
          padding: 20px 0 25px;
          font-size: 1.4rem;
          line-height: 1.6; }
      #conts .clinicSec .flexBox .mapBox {
        width: auto; }
        #conts .clinicSec .flexBox .mapBox iframe {
          width: 100%;
          height: 260px; }
    #conts .clinicSec .messageBox {
      margin-top: 30.7vw;
      position: relative; }
      #conts .clinicSec .messageBox::before {
        content: "";
        display: block;
        width: 100%;
        height: 125vw;
        background: url(../img/bg_clinic02_sp.png) no-repeat center top;
        background-size: contain;
        position: absolute;
        top: 50vw;
        left: 0; }
      #conts .clinicSec .messageBox .messageWrap {
        position: relative;
        z-index: 10; }
      #conts .clinicSec .messageBox p {
        font-size: 4.3vw;
        line-height: 2.1;
        text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5), -10px -10px 20px rgba(0, 0, 0, 0.5); }
        #conts .clinicSec .messageBox p + p {
          margin-top: 30px; }
    #conts .clinicSec .logo {
      margin-top: -40px; }
      #conts .clinicSec .logo img {
        width: 305px; }
    #conts .clinicSec .contactBtn {
      margin-top: 10px; }
      #conts .clinicSec .contactBtn .imgBox {
        padding-left: 15px; }
        #conts .clinicSec .contactBtn .imgBox img {
          width: 293px; } }

//////////////////////////////////////////////////
// Reset
//////////////////////////////////////////////////
abbr, address, article, aside, audio, b, blockquote, body, button, caption, cite, code, dd, del, dfn, dialog, div, dl, dt, em, fieldset, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 1em;
}
html {
    font-size: 62.5%;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}
input, option, select, table, textarea {
    line-height: 1.1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
fieldset {
    min-width: 100%;
    width: 100%;
    display: table;
}
button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}
:focus {
    outline: 0;
}
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}
img {
    vertical-align: top;
    max-width: 100%;
    height: auto;
}
*, *:before, *:after {
    box-sizing: border-box;
}

@charset "UTF-8";
@import './import';

/* =========================================================
	Reset
========================================================= */
@import './_include/reset';

/* =========================================================
	Base Setting
========================================================= */
@import './_include/base';

/* =========================================================
	Common Layout
========================================================= */
#wrap {
	width: 100%;
	min-width: 1366px;
	padding-top: 100px;
	background: #222;
	overflow: hidden;
	&.menuActive {
		position: fixed;
		left: 0;
		z-index: 1;
	}
}
@include mq('for-sp') {
	#wrap {
		min-width: auto;
		padding-top: 0;
		background: none;
	}
	&.bg-active {
		#conts {
			.summarySec {
				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 100vh;
					background: url(../img/bg_summary02_sp.jpg) no-repeat;
					background-size: cover;
					position: fixed;
					top: 0;
					z-index: -1;
				}
			}
		}
	}
}

/* Header
--------------------------------------------------------- */
#header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	&.menuActive {
		header {
			padding-right: 17px;
		}
	}
	header {
		display: flex;
		width: 1366px;
		height: 100px;
		margin: 0 auto;
		box-sizing: content-box;
		.logo {
			padding: 15px 0 15px 30px;
		}
		.menu {
			margin-left: auto;
			padding: 45px 40px 0 0;
			i {
				display: block;
				width: 72px;
				height: 15px;
				cursor: pointer;
				position: relative;
				&::before,
				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 3px;
					background: #a68d70;
					position: absolute;
					transform: rotate(0deg);
					transition: all .3s ease;
				}
				&::after {
					transform: translateY(12px);
				}
			}
			&.open {
				i {
					&::before, &::after {
						background: #fff;
					}
					&::before {
						transform: rotate(-25deg) translateY(0px);
					}
					&::after {
						transform: rotate(25deg) translateY(0px);
					}
				}
			}
		}
	}
}
@include mq('for-sp') {
	#header {
		&.menuActive {
			header {
				padding-right: 0;
			}
		}
		header {
			width: auto;
			height: 55px;
			.logo {
				padding: 10px 0 10px 10px;
				img {
					width: 196px;
				}
			}
			.menu {
				padding: 22px 20px 0 0;
				i {
					width: 30px;
					height: 10px;
					&::before,
					&::after {
						height: 2px;
					}
					&::after {
						transform: translateY(8px);
					}
				}
			}
		}
	}
}

/* Slide Menu
--------------------------------------------------------- */
#slideMenu {
	width: 100%;
	min-width: 1366px;
    height: 100%;
    position: relative;
	overflow: hidden;
	&.active {
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1000;
		.menuWrap {
			.menuOuter {
				.cvBanner {
					display: block;
				}
			}
		}
	}
	.menuWrap {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1000;
		.menuOuter {
			width: 100%;
			height: 100%;
			background:
				url(../img/bg_navi01_pc.png) no-repeat 12px bottom,
				url(../img/bg_navi02_pc.jpg) no-repeat center top,
				url(../img/bg_navi03_pc.jpg) no-repeat center bottom;
			background-color: #0098df;
			position: absolute;
			top: 0;
			right: 0;
			.menuNav {
				width: 1000px;
				margin: 0 auto;
				ul {
					width: 400px;
					margin-left: auto;
					padding-top: 135px;
					li {
						border-bottom: 2px solid #fff;
						a {
							display: block;
							padding: 10px 15px 15px;
							color: #222;
							font-size: 2.6rem;
							font-weight: bold;
							text-decoration: none;
							.en {
								display: inline-block;
								margin-bottom: 4px;
								color: #fff;
								font-size: 1.6rem;
							}
						}
					}
				}
			}
			.cvBanner {
				display: none;
				position: fixed;
				top: 55%;
				right: 0;
				z-index: 100;
				a {
					display: inline-block;
					padding: 30px 14px;
					background: #006ddb;
					color: #fff;
					font-size: 2.4rem;
					font-weight: bold;
					text-decoration: none;
					writing-mode: vertical-rl;
					text-orientation: upright;
					transition: all .3s ease;
					&:hover {
						background: #a68d70;
					}
					&::before {
						content: "";
						display: inline-block;
						width: 73px;
						height: 51px;
						margin-bottom: 20px;
						background: url(../img/icon_cv01_pc.png) no-repeat;
						vertical-align: middle;
					}
					span {
						vertical-align: middle;
					}
				}
			}
		}
	}
}
@include mq('for-sp') {
	#slideMenu {
		min-width: auto;
		.menuWrap {
			.menuOuter {
				background-image: none;
				.menuInner {
					height: 100%;
					margin-top: 70px;
					padding-bottom: 70px;
					overflow-y: scroll;
					-webkit-overflow-scrolling: touch;
					.menuNav {
						width: auto;
						ul {
							width: auto;
							padding: 0 40px;
							li {
								border-width: 1px;
								a {
									padding: 10px 0;
									font-size: 2.0rem;
									.en {
										margin-bottom: 6px;
										font-size: 1.3rem;
									}
								}
							}
						}
					}
					.inquiryList {
						margin-top: 30px;
						padding: 0 40px 20px;
						li {
							a {
								display: block;
								padding: 20px 0;
								background: #006ddb;
								border-radius: 50px;
								color: #fff;
								font-size: 1.6rem;
								font-weight: bold;
								text-align: center;
								text-decoration: none;
							}
							& + li {
								margin-top: 10px;
								a {
									padding: 16px 0;
									font-size: 2.0rem;
									&::before {
										content: "";
										display: inline-block;
										width: 24px;
										height: 26px;
										margin-right: 5px;
										background: url(../img/icon_navi01_sp.png) no-repeat;
										background-size: 24px 26px;
										vertical-align: middle;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* Main Visual
--------------------------------------------------------- */
#mainVisual {
	background: url(../img/bg_mainvisual01_pc.png) no-repeat center bottom;
	.inner {
		margin: 0 auto;
		padding: 340px 0 170px;
		position: relative;
		.ttl {
			display: inline-block;
			padding-bottom: 4px;
			color: #FFF;
			font-size: 5.8rem;
			font-weight: bold;
			line-height: 1.6;
			position: absolute;
			top: 115px;
			left: 20%;
			&::after {
				content: "";
				display: block;
				width: 0;
				height: 4px;
				background: #0098df;
				position: absolute;
				bottom: 0;
				left: 0;
				transition: width .8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.8s;
			}
			&.active {
				&::after {
					width: 100%;
				}
			}
			&.locked {
				&::after {
					width: 100%;
					transition: none;
				}
			}
		}
		.catch {
			padding-top: 250px;
			color: #FFF;
			font-size: 4.0rem;
			font-weight: bold;
			text-align: center;
			line-height: 1.1;
			.en {
				font-size: 2.2rem;
				font-weight: normal;
			}
		}
	}
}
#description {
	width: 1366px;
	margin: 0 auto;
	padding: 95px 180px 70px;
	p {
		color: #FFF;
		font-size: 3.8rem;
		font-weight: bold;
		line-height: 1.8;
	}
	.logo {
		margin-top: -60px;
		text-align: center;
	}
}
@include mq('for-pc') {
	#wrap.menuActive {
		#mainVisual {
			margin-right: 17px;
		}
	}
}
@include mq('for-sp') {
	#mainVisual {
		padding-top: 55px;
		background-image: url(../img/bg_mainvisual01_sp.png);
		background-color: #222;
		background-size: contain;
		.inner {
			padding: 62vw 0 180px;
			.ttl {
				margin: 0;
				font-size: 3.0rem;
				letter-spacing: 5px;
				top: 14%;
				left: 40px;
			}
			.catch {
				padding-top: 165px;
				font-size: 5.4vw;
				.en {
					display: inline-block;
					margin-top: 15px;
					font-size: 1.1rem;
				}
			}
		}
	}
	#description {
		width: auto;
		padding: 45px 0 60px;
		background-color: #222;
		p {
			padding: 0 30px;
			font-size: 2.2rem;
			line-height: 1.7;
		}
		.logo {
			margin-top: -40px;
			padding: 0 13px;
		}
	}
}

/* Contents
--------------------------------------------------------- */
#conts .inner {
	width: 1000px;
	margin: 0 auto;
}
@include mq('for-sp') {
	#conts .inner {
		width: auto;
	}
}

/* CV Banner
--------------------------------------------------------- */
#cvBanner {
	position: fixed;
	top: 55%;
	right: 0;
	z-index: 100;
	a {
		display: inline-block;
		padding: 30px 14px;
		background: #006ddb;
		color: #fff;
		font-size: 2.4rem;
		font-weight: bold;
		text-decoration: none;
        writing-mode: vertical-rl;
		text-orientation: upright;
		transition: all .3s ease;
		&:hover {
			background: #a68d70;
		}
		&::before {
			content: "";
			display: inline-block;
			width: 73px;
			height: 51px;
			margin-bottom: 20px;
			background: url(../img/icon_cv01_pc.png) no-repeat;
			vertical-align: middle;
		}
		span {
			vertical-align: middle;
		}
	}
}
#cvBox {
	display: none;
}
@include mq('for-sp') {
	#cvBanner {
		display: none;
	}
	#cvBox {
		display: block;
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		transition: all .3s ease;
		z-index: 100;
		ul {
			display: flex;
			li {
				width: 50%;
				a {
					display: block;
					width: 100%;
					color: #fff;
					font-size: 3.8vw;
					font-weight: bold;
					text-decoration: none;
					text-align: center;
					&::before {
						content: "";
						display: inline-block;
						margin-right: 10px;
						vertical-align: middle;
					}
					span {
						vertical-align: middle;
					}
				}
				&:nth-child(1) {
					background: #a68d70;
					a {
						padding: 15px 10px;
						&::before {
							width: 24px;
							height: 26px;
							background: url(../img/icon_cv01_sp.png) no-repeat;
							background-size: 24px 26px;
						}
					}
				}
				&:nth-child(2) {
					background: #006ddb;
					a {
						padding: 13px 10px;
						&::before {
							width: 18px;
							height: 30px;
							background: url(../img/icon_cv02_sp.png) no-repeat;
							background-size: 18px 30px;
						}
					}
				}
			}
		}
	}
}

/* Footer
--------------------------------------------------------- */
footer {
	width: 1366px;
	margin: 0 auto;
	padding: 78px 0 70px;
	.logo {
		text-align: center;
	}
	.copyRight {
		margin-top: 40px;
		color: #FFF;
		font-size: 1.3rem;
		text-align: center;
	}
}
@include mq('for-sp') {
	footer {
		width: auto;
		padding: 50px 0 25px;
		background-color: #222;
		.logo {
			img {
				width: 261px;
			}
		}
		.copyRight {
			margin-top: 25px;
			font-size: 1.0rem;
		}
	}
}

/* =========================================================
	Common Style
========================================================= */
#conts {
	/* Common Title */
	.cmnTtl01 {
		padding-bottom: 32px;
		text-align: center;
		position: relative;
		&::after {
			content: "";
			display: block;
			width: 80px;
			height: 4px;
			background: #a68d70;
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -40px;
		}
	}
	/* Contact Button */
	.contactBtn {
		text-align: center;
		a {
			display: inline-block;
			width: 480px;
			padding: 35px 0 30px;
			background: #006ddb;
			border-radius: 50px;
			color: #FFF;
			font-size: 2.4rem;
			font-weight: bold;
			text-decoration: none;
		}
		.finish {
			display: inline-block;
			margin-bottom: -5px;
			color: #006ddb;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.9;
			&::before, &::after {
				display: inline-block;
				font-size: 4.0rem;
				vertical-align: sub;
			}
			&::before {
				content: "＼";
				margin-right: 10px;
				transform: rotateY(40deg);
			}
			&::after {
				content: "／";
				margin-left: 10px;
				transform: rotateY(-40deg);
			}
		}
	}
}
@include mq('for-pc') {
	#conts {
		.contactBtn a {
			transition: all .3s ease;
			&:hover {
				background: #a68d70;
			}
		}
	}
}
@include mq('for-sp') {
	#conts {
		.cmnTtl01 {
			padding-bottom: 20px;
			&::after {
				width: 60px;
				height: 3px;
				margin-left: -30px;
			}
		}
		/* Contact Button */
		.contactBtn {
			padding: 0 20px;
			a {
				width: 100%;
				padding: 20px 0;
				font-size: 5.1vw;
			}
			.finish {
				font-size: 2.0rem;
				&::before, &::after {
					font-size: 3.0rem;
				}
				&::before {
					margin-right: 5px;
				}
				&::after {
					margin-left: 5px;
				}
			}
		}
	}
}

/* Before After Slider
--------------------------------------------------------- */
.ba-slider {
	position: relative;
	overflow: hidden;
	img {
		display: block;
		width: 100%;
		max-width: none;
	}
	.resize {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 50%;
		overflow: hidden;
	}
	.handle {
		position: absolute; 
		left: 50%;
		top: 0;
		bottom: 0;
		width: 4px;
		margin-left: -2px;
		background: #222222;
		cursor: pointer;
		&::after {
			content: "";
			width: 92px;
			height: 92px;
			margin: -44px 0 0 -44px;
			background: url(../img/icon_handle.png) no-repeat;
			background-size: 92px 92px;
			position: absolute;
			top: 50%;
		}
	}
}

@include mq('for-sp') {
	.ba-slider {
		.handle {
			width: 3px;
			margin-left: -1.5px;
			&::after {
				width: 46px;
				height: 46px;
				margin: -23px 0 0 -23px;
				background-size: 46px 46px;
			}
		}
	}
}

/* Animation
--------------------------------------------------------- */
[data-animate="fadeInUp"],
[data-animate="fadeInTtl"] {
	opacity: 0;
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
	animation-duration: 2s !important;
}

.fadeInUp2 {
	opacity: 0;
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
	transition: all .8s ease;
	&.active {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes fadeInTtl {
	from {
		transform: translateY(100%);
		opacity: 1;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fadeInTtl {
	from {
		transform: translateY(100%);
		opacity: 1;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.fadeInTtl {
	p {
		overflow: hidden;
		span {
			display: inline-block;
		}
	}
	&.active {
		p {
			span {
				transform: translateY(100%);
				animation-name: fadeInTtl;
				animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
				animation-duration: .8s;
				animation-delay: 0s;
				animation-fill-mode: forwards;
			}
			&:nth-child(1) {
				span {
					&:nth-child(1) {
						animation-delay: 0.5s;
					}
					&:nth-child(2) {
						animation-delay: 0.55s;
					}
					&:nth-child(3) {
						animation-delay: 0.6s;
					}
					&:nth-child(4) {
						animation-delay: 0.65s;
					}
				}
			}
			&:nth-child(2) {
				span {
					&:nth-child(1) {
						animation-delay: 0.7s;
					}
					&:nth-child(2) {
						animation-delay: 0.75s;
					}
					&:nth-child(3) {
						animation-delay: 0.8s;
					}
					&:nth-child(4) {
						animation-delay: 0.85s;
					}
					&:nth-child(5) {
						animation-delay: 0.9s;
					}
				}
			}
			&:nth-child(3) {
				span {
					&:nth-child(1) {
						animation-delay: 0.95s;
					}
					&:nth-child(2) {
						animation-delay: 1.0s;
					}
					&:nth-child(3) {
						animation-delay: 1.05s;
					}
					&:nth-child(4) {
						animation-delay: 1.1s;
					}
					&:nth-child(5) {
						animation-delay: 1.15s;
					}
				}
			}
		}
	}
}

/* =========================================================
	Contents
========================================================= */
#conts {
	.summarySec {
		background: #e9e9e9 url(../img/bg_summary02_pc.jpg) no-repeat fixed 50% bottom;
		background-size: cover;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 662px;
			height: 228px;
			background: url(../img/bg_section01.png) no-repeat;
			position: absolute;
			top: -2px;
			left: 0;
			z-index: 10;
		}
		& > .inner {
			padding-top: 205px;
		}
		h2 {
			color: #a68d70;
			font-size: 4.0rem;
			font-weight: bold;
			text-align: center;
		}
		p {
			font-size: 1.8rem;
			line-height: 1.9;
			& + p {
				margin-top: 35px;
			}
			.blue {
				color: #0098df;
				font-weight: bold;
			}
		}
		.leadBox {
			margin-top: 30px;
			padding: 0 100px;
		}
		.difficultSec {
			margin-top: 85px;
			padding: 100px 100px 75px;
			background: #d2d2d2;
			position: relative;
			&::after {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border: 60px solid transparent;
				border-top: 60px solid #d2d2d2;
				position: absolute;
				left: 50%;
				bottom: -120px;
				margin-left: -60px;
				z-index: 99;
			}
			h3 {
				font-size: 4.0rem;
				font-weight: bold;
				text-align: center;
				& + p {
					margin-top: 45px;
				}
			}
			p {
				font-size: 1.6rem;
			}
			.flexBox {
				display: flex;
				margin-top: 25px;
				.imgBox {
					width: 262px;
				}
				.textBox {
					width: 470px;
					margin-left: auto;
					padding-top: 10px;
				}
				& + p {
					margin-top: 30px;
				}
			}
		}
		.continueSec {
			margin-top: 125px;
			padding: 0 100px;
			p {
				font-size: 1.6rem;
			}
			.ttlBox {
				text-align: center;
				h3 {
					display: inline-block;
					padding-bottom: 10px;
					border-bottom: 2px solid #a68d70;
					font-size: 4.0rem;
					font-weight: bold;
				}
				& + p {
					margin-top: 50px;
				}
			}
		}
		.catchBox {
			margin-top: 70px;
			background: #222222 url(../img/bg_catch01_pc.png) no-repeat center;
			background-size: cover;
			text-align: center;
			p {
				display: inline-block;
				padding: 30px 110px 25px 145px;
				color: #a68d70;
				font-size: 3.6rem;
				font-weight: bold;
				line-height: 1;
				position: relative;
				&::before, &::after {
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
				}
				&::before {
					width: 116px;
					height: 162px;
					background: url(../img/icon_summary01_pc.png) no-repeat;
					left: 0;
				}
				&::after {
					width: 84px;
					height: 162px;
					background: url(../img/icon_summary02_pc.png) no-repeat;
					right: 0;
				}
			}
		}
		.contactBtn {
			padding: 55px 0 65px;
			position: relative;
			background: #f7f7f7;
			&::after {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border: 90px solid transparent;
				border-top: 90px solid #f7f7f7;
				position: absolute;
				left: 50%;
				bottom: -180px;
				margin-left: -90px;
				z-index: 99;
			}
		}
		.bfBox {
			padding: 160px 0;
			background: #222222;
			.tabList {
				display: flex;
				width: 500px;
				padding: 0 20px;
				li {
					width: 200px;
					&:last-child {
						margin-left: auto;
					}
					a {
						display: block;
						padding: 12px 0 8px;
						background: #979797;
						border: 5px solid #979797;
						border-radius: 20px;
						color: #FFF;
						font-size: 3.2rem;
						font-weight: bold;
						text-align: center;
						text-decoration: none;
						line-height: 1;
					}
					&.active a {
						background: #a68d70;
						border-color: #FFF;
					}
				}
			}
			.flexBox {
				display: flex;
				align-items: center;
				margin-top: 20px;
				.slideBox {
					width: 500px;
				}
				.textBox {
					width: 460px;
					margin-left: auto;
					p {
						color: #FFF;
						&.ttl {
							font-size: 2.0rem;
							font-weight: bold;
						}
					}
					dl {
						display: flex;
						flex-wrap: wrap;
						margin-top: 10px;
						dt, dd {
							color: #FFF;
							font-size: 1.6rem;
							line-height: 2.0;
						}
						dt {
							width: 160px;
						}
						dd {
							width: 300px;
						}
					}
				}
			}
		}
	}
	.menuSec {
		padding: 150px 0 100px;
		background: #0098df;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 668px;
			height: 228px;
			background: url(../img/bg_section02.png) no-repeat;
			position: absolute;
			top: 0;
			left: 0;
		}
		.cmnTtl01 {
			&::after {
				background: #fff;
			}
		}
		p {
			color: #fff;
			font-size: 1.6rem;
			line-height: 1.9;
			& + p {
				margin-top: 30px;
			}
		}
		.catchBox {
			display: flex;
			align-items: center;
			margin-top: 70px;
			.imgBox {
				width: 470px;
			}
			.textBox {
				width: 470px;
				margin-left: auto;
				padding-bottom: 50px;
				.catch {
					font-size: 3.2rem;
					font-weight: bold;
					line-height: 1.6;
				}
			}
		}
		.detailSec {
			margin-top: 90px;
			position: relative;
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 75vw;
				background: url(../img/bg_menu01_pc.png) no-repeat center top;
				background-size: contain;
				position: absolute;
				top: -140px;
				left: 0;
			}
			.inner {
				position: relative;
				z-index: 10;
			}
			h3 {
				padding-bottom: 20px;
				border-bottom: 4px solid #ccf11f;
			}
			.subTtl {
				margin-top: 20px;
				color: #fff;
				font-size: 3.4rem;
				font-weight: bold;
				line-height: 1.0;
			}
			.flexBox {
				display: flex;
				.imgBox {
					text-align: center;
				}
				.textBox {
					width: 480px;
					margin-left: auto;
				}
			}
			.catchText {
				font-size: 3.2rem;
				font-weight: bold;
				text-align: center;
			}
			&:nth-of-type(1) {
				.flexBox {
					margin-top: 65px;
					.imgBox {
						width: 385px;
						margin-left: 32px;
					}
					.textBox {
						padding-top: 15px;
					}
				}
				.catchText {
					margin-top: 85px;
				}
			}
			&:nth-of-type(2) {
				.flexBox {
					margin-top: 2px;
					.imgBox {
						order: 2;
						width: 381px;
						margin-right: 42px;
					}
					.textBox {
						order: 1;
						padding-top: 85px;
						margin: 0 auto 0 0;
					}
				}
				.catchText {
					margin-top: 60px;
				}
			}
			&:nth-of-type(3) {
				.flexBox {
					margin-top: 15px;
					.imgBox {
						width: 389px;
						margin-left: 54px;
					}
					.textBox {
						padding-top: 90px;
					}
				}
				.catchText {
					margin-top: 65px;
				}
			}
			.meritSec {
				margin-top: 80px;
				h4 {
					padding-bottom: 8px;
					border-bottom: 1px solid #fff;
					color: #fff;
					font-size: 4.6rem;
					font-weight: bold;
				}
				.meritList {
					display: flex;
					margin-top: 35px;
					li {
						width: 310px;
						& + li {
							margin-left: 35px;
						}
					}
				}
				.riskSec {
					margin-top: 40px;
					padding: 30px 30px 20px;
					border: 8px solid #fff;
					h5 {
						color: #fff;
						font-size: 2.0rem;
						font-weight: bold;
					}
					p {
						margin-top: 10px;
					}
				}
			}
			.linkBtn {
				margin-top: 40px;
				text-align: center;
				a {
					display: inline-block;
					width: 154px;
					padding: 63px 0 59px;
					background: #45afe0;
					border: 4px solid #fff;
					border-radius: 100px;
					color: #fff;
					font-size: 2.2rem;
					font-weight: bold;
					text-decoration: none;
					position: relative;
					&::after {
						content: "";
						width: 17px;
						height: 17px;
						border-top: solid 4px #fff;
						border-right: solid 4px #fff;
						transform: rotate(135deg);
						position: absolute;
						bottom: 18px;
						left: 50%;
						margin-left: -8px;
					}
				}
			}
		}
		.contactBtn {
			margin-top: 85px;
			.finish {
				color: #fff;
			}
			a {
				position: relative;
				&::after {
					content: "";
					display: block;
					width: 127px;
					height: 184px;
					background: url(../img/icon_menu01_pc.png) no-repeat;
					position: absolute;
					bottom: 0;
					right: 10px;
				}
				span {
					position: relative;
					z-index: 10;
				}
			}
		}
	}
	.priceSec {
		padding: 180px 0 90px;
		background: #f7f7f7;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 804px;
			height: 282px;
			background: url(../img/bg_section03.png) no-repeat;
			position: absolute;
			top: 0;
			left: 0;
		}
		.leadBox {
			margin-top: 70px;
			p {
				font-size: 1.8rem;
				line-height: 1.9;
				& + p {
					margin-top: 35px;
				}
			}
		}
		.priceList {
			display: flex;
			margin-top: 55px;
			li {
				width: 310px;
				padding: 40px 0 45px;
				border-radius: 30px;
				position: relative;
				&::after {
					content: "";
					display: block;
					position: absolute;
				}
				&:nth-child(1) {
					background: #df6352;
					&::after {
						width: 149px;
						height: 132px;
						background: url(../img/bg_price01_pc.png) no-repeat;
						bottom: -19px;
						right: -12px;
					}
				}
				&:nth-child(2) {
					background: #9dc93e;
					&::after {
						width: 92px;
						height: 146px;
						background: url(../img/bg_price02_pc.png) no-repeat;
						bottom: -27px;
						right: -10px;
					}
				}
				&:nth-child(3) {
					background: #efc866;
					&::after {
						width: 76px;
						height: 151px;
						background: url(../img/bg_price03_pc.png) no-repeat;
						bottom: -22px;
						right: -24px;
					}
				}
				& + li {
					margin-left: 35px;
				}
				.priceWrap {
					position: relative;
					z-index: 10;
				}
				p {
					color: #fff;
					font-weight: bold;
					text-align: center;
				}
				.ttl {
					padding-bottom: 8px;
					font-size: 2.6rem;
					position: relative;
					&::after {
						content: "";
						display: block;
						width: 200px;
						height: 2px;
						background: #fff;
						position: absolute;
						bottom: 0;
						left: 50%;
						margin-left: -100px;
					}
				}
				.period {
					margin-top: 15px;
					font-size: 2.0rem;
				}
				.price {
					margin-top: 10px;
					font-size: 3.2rem;
					.yen {
						font-size: 2.7rem;
					}
				}
				.limited {
					margin-top: 20px;
					font-size: 2.7rem;
				}
				.att {
					margin-top: 12px;
					font-size: 1.6rem;
				}
			}
		}
		.courseSec {
			margin-top: 130px;
			h3 {
				padding: 0 6px 2px;
				font-size: 4.6rem;
				font-weight: bold;
			}
			section {
				margin-top: 20px;
				padding-top: 22px;
				position: relative;
				&::before {
					content: "";
					display: block;
					width: 440px;
					height: 5px;
					background: #000;
					position: absolute;
					top: 0;
					left: 0;
				}
				& + section {
					margin-top: 60px;
				}
				h4 {
					font-size: 2.4rem;
					font-weight: bold;
				}
				.flexBox {
					display: flex;
					margin-top: 15px;
					.imgBox {
						width: 170px;
					}
					.pcTable {
						width: 830px;
						border-collapse: collapse;
						th, td {
							border: 3px solid #0098df;
							font-size: 1.8rem;
							font-weight: bold;
							text-align: center;
							vertical-align: middle;
						}
						th {
							height: 58px;
							background: #e5e5e5;
							border-left: none;
							border-right: none;
						}
						td {
							width: 215px;
							background: #fff;
							.price {
								font-size: 2.8rem;
							}
							.yen {
								font-size: 2.1rem;
							}
						}
						th + td {
							border-left: none;
						}
						tr:nth-child(2) {
							th {
								height: 59px;
							}
						}
						&.coolTable {
							td {
								width: 129px;
								.price {
									font-size: 2.3rem;
								}
								.yen {
									font-size: 1.7rem;
								}
							}
						}
					}
				}
			}
			.attList {
				margin-top: 35px;
				li {
					margin-left: 1em;
					text-indent: -1em;
					font-size: 1.4rem;
					line-height: 1.8;
				}
			}
			.cardBox {
				margin-top: 65px;
				text-align: center;
			}
		}
		.contactBtn {
			margin-top: 115px;
			a {
				position: relative;
				&::after {
					content: "";
					display: block;
					width: 115px;
					height: 169px;
					background: url(../img/icon_price01_pc.png) no-repeat;
					position: absolute;
					bottom: 0;
					left: 20px;
				}
				span {
					position: relative;
					z-index: 10;
				}
			}
		}
	}
	.clinicSec {
		padding: 130px 0 85px;
		background:
			url(../img/bg_clinic03_pc.jpg) no-repeat left 1020px,
			url(../img/bg_clinic04_pc.jpg) repeat-x left 1020px,
			linear-gradient(to bottom, #222, #222 50%, #f7f7f7 50%, #f7f7f7 100%);
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 624px;
			height: 192px;
			background: url(../img/bg_section04.png) no-repeat;
			position: absolute;
			top: 0;
			left: 0;
		}
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 95vw;
			background: url(../img/bg_clinic02_pc.png) no-repeat center top;
			background-size: contain;
			position: absolute;
			top: 135px;
			left: 0;
		}
		.clinicWrap {
			position: relative;
			z-index: 10;
		}
		.ttlBox {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 300px;
			margin-top: 80px;
			background: url(../img/bg_clinic01_pc.jpg) no-repeat;
			background-size: cover;
		}
		.flexBox {
			display: flex;
			margin-top: 80px;
			.textBox {
				order: 2;
				width: 480px;
				margin-left: auto;
				padding-top: 65px;
				dl {
					dt {
						padding-bottom: 5px;
						border-bottom: 2px solid #a68d70;
						color: #a68d70;
						font-size: 2.4rem;
						font-weight: bold;
					}
					dd {
						padding: 20px 3px 45px;
						color: #fff;
						font-size: 1.8rem;
					}
				}
			}
			.mapBox {
				order: 1;
				width: 480px;
			}
		}
		.messageBox {
			margin-top: 120px;
			p {
				color: #fff;
				font-size: 2.4rem;
				font-weight: bold;
				text-align: center;
				line-height: 1.9;
				& + p {
					margin-top: 50px;
				}
				&:nth-child(n + 4) {
					color: #231815;
					text-shadow: 10px 10px 20px rgba(227,227,227,0.9), -10px -10px 20px rgba(227,227,227,0.9);
				}
			}
		}
		.logo {
			margin-top: -65px;
			text-align: center;
		}
		.contactBtn {
			margin-top: -27px;
			.imgBox {
				padding-right: 25px;
			}
		}
	}
}
@include mq('for-pc') {
	#conts {
		.menuSec {
			.detailSec {
				.linkBtn {
					a {
						transition: all .3s ease;
						&:hover {
							opacity: .7;
						}
					}
				}
			}
		}	
	}
}
@include mq('for-sp') {
	#conts {
		.summarySec {
			background-image: none;
			background-color: transparent;
			&::before {
				width: 300px;
				height: 103px;
				background-size: 300px 103px;
				top: 0;
			}
			& > .inner {
				padding-top: 140px;
			}
			h2 {
				font-size: 7vw;
			}
			p {
				font-size: 1.4rem;
				& + p {
					margin-top: 25px;
				}
			}
			.leadBox {
				margin-top: 25px;
				padding: 0 15px;
			}
			.difficultSec {
				margin-top: 60px;
				padding: 50px 15px;
				&::after {
					border-width: 30px;
					bottom: -60px;
					margin-left: -30px;
				}
				h3 {
					font-size: 7vw;
					line-height: 1.6;
					& + p {
						margin-top: 20px;
					}
				}
				p {
					font-size: 1.4rem;
				}
				.flexBox {
					display: block;
					.imgBox {
						width: auto;
						text-align: center;
						img {
							width: 226px;
						}
					}
					.textBox {
						width: auto;
						margin-top: 25px;
						padding-top: 0;
					}
				}
			}
			.continueSec {
				margin-top: 60px;
				padding: 0 15px;
				.ttlBox {
					h3 {
						font-size: 6.2vw;
						border-bottom-width: 1px;
					}
					& + p {
						margin-top: 25px;
					}
				}
				p {
					font-size: 1.4rem;
				}
			}
			.catchBox {
				margin-top: 65px;
				background-image: url(../img/bg_catch01_sp.png);
				p {
					display: block;
					padding: 16px 0;
					font-size: 6.7vw;
					line-height: 1.4;
					&::before, &::after {
						z-index: 0;
					}
					&::before {
						width: 86.5px;
						height: 150.5px;
						background-image: url(../img/icon_summary01_sp.png);
						background-size: 86.5px 150.5px;
					}
					&::after {
						width: 80.5px;
						height: 156px;
						background-image: url(../img/icon_summary02_sp.png);
						background-size: 80.5px 156px;
					}
					span {
						position: relative;
						z-index: 10;
					}
				}
			}
			.contactBtn {
				padding: 45px 20px 60px;
				&::after {
					border-width: 35px;
					bottom: -70px;
					margin-left: -35px;
				}
			}
			.bfBox {
				padding: 60px 0;
				.tabList {
					width: auto;
					li {
						width: 45%;
						a {
							padding: 10px 0;
							border-width: 3px;
							border-radius: 10px;
							font-size: 6.4vw;
						}
					}
				}
				.flexBox {
					display: block;
					margin-top: 30px;
					.slideBox {
						width: auto;
					}
					.textBox {
						width: auto;
						margin-top: 25px;
						padding: 0 20px;
						p {
							&.ttl {
								font-size: 1.6rem;
							}
						}
						dl {
							display: block;
							overflow: hidden;
							dt, dd {
								float: left;
								width: auto;
								font-size: 3.5vw;
							}
							dt {
								clear: both;
							}
						}
					}
				}
			}
		}
		.menuSec {
			padding-top: 150px;
			&::before {
				width: 302px;
				height: 103px;
				background-size: 302px 103px;
			}
			.cmnTtl01 {
				img {
					width: 105px;
				}
			}
			p {
				font-size: 1.4rem;
				& + p {
					margin-top: 25px;
				}
			}
			.catchBox {
				display: block;
				margin-top: 50px;
				padding: 0 20px;
				.imgBox {
					width: auto;
					text-align: center;
				}
				.textBox {
					width: auto;
					padding: 35px 0 0;
					.catch {
						font-size: 2.6rem;
						text-align: center;
						line-height: 1.4;
						& + p {
							margin-top: 20px;
						}
					}
				}
			}
			.detailSec {
				margin-top: 50px;
				&::before {
					height: 95vw;
					background-image: url(../img/bg_menu01_sp.png);
					top: 25px;
				}
				.inner {
					padding: 0 20px;
				}
				h3 {
					padding-bottom: 10px;
				}
				.subTtl {
					margin-top: 10px;
					font-size: 1.8rem;
				}
				.flexBox {
					display: block;
					.imgBox {
						width: auto !important;
						text-align: center;
					}
					.textBox {
						width: auto;
					}
				}
				.catchText {
					margin-top: 40px !important;
					font-size: 2.2rem;
					line-height: 1.5;
				}
				&:nth-of-type(1) {
					h3 {
						img {
							width: 66.7vw;
						}
					}
					.flexBox {
						margin-top: 20px;
						.imgBox {
							margin-left: 10px;
							img {
								width: 269px;
							}
						}
						.textBox {
							padding-top: 20px;
						}
					}
				}
				&:nth-of-type(2) {
					h3 {
						img {
							width: 75.8vw;
						}
					}
					.flexBox {
						margin-top: 30px;
						.imgBox {
							margin: 0 0 0 10px;
							img {
								width: 234px;
							}
						}
						.textBox {
							padding-top: 50px;
						}
					}
				}
				&:nth-of-type(3) {
					h3 {
						img {
							width: 83.5vw;
						}
					}
					.flexBox {
						margin-top: 10px;
						.imgBox {
							margin-left: 15px;
							img {
								width: 251px;
							}
						}
						.textBox {
							padding-top: 45px;
						}
					}
				}
				.meritSec {
					margin-top: 40px;
					h4 {
						padding-bottom: 6px;
						font-size: 2.6rem;
						line-height: 1.3;
					}
					.meritList {
						display: block;
						margin-top: 20px;
						li {
							width: auto;
							text-align: center;
							& + li {
								margin: 20px 0 0;
							}
						}
					}
					.riskSec {
						margin-top: 30px;
						padding: 15px 20px;
						border-width: 5px;
						h5 {
							font-size: 1.6rem;
							line-height: 1.6;
						}
						p {
							font-size: 1.2rem;
						}
					}
				}
				.linkBtn {
					margin-top: 30px;
					a {
						width: 115px;
						padding: 44px 0;
						font-size: 1.8rem;
						&::after {
							width: 14px;
							height: 14px;
							border-width: 3px;
							bottom: 20px;
							margin-left: -7px;
						}
					}
				}
			}
			.contactBtn {
				margin-top: 35px;
				a {
					&::after {
						width: 70px;
						height: 108px;
						background-image: url(../img/icon_menu01_sp.png);
						background-size: 70px 108px;
						right: 15px;
					}
				}
			}
		}
		.priceSec {
			padding-top: 130px;
			&::before {
				width: 308px;
				height: 103px;
				background-size: 308px 103px;
			}
			.inner {
				padding: 0 20px;
			}
			.cmnTtl01 {
				img {
					width: 106px;
				}
			}
			.leadBox {
				margin-top: 35px;
				p {
					font-size: 1.4rem;
					& + p {
						margin-top: 25px;
					}
				}
			}
			.priceList {
				display: block;
				margin-top: 30px;
				li {
					width: auto;
					padding: 40px 0;
					&:nth-child(1) {
						&::after {
							width: 137px;
							height: 124px;
							background-image: url(../img/bg_price01_sp.png);
							background-size: 137px 124px;
							bottom: -2px;
							right: -14px;
						}
					}
					&:nth-child(2) {
						&::after {
							width: 89px;
							height: 140px;
							background-image: url(../img/bg_price02_sp.png);
							background-size: 89px 140px;
							bottom: -14px;
							right: auto;
							left: -6px;
						}
					}
					&:nth-child(3) {
						&::after {
							width: 79px;
							height: 195px;
							background-image: url(../img/bg_price03_sp.png);
							background-size: 79px 195px;
							bottom: -32px;
							right: -2px;
						}
					}
					& + li {
						margin: 15px 0 0;
					}
					.ttl {
						padding-bottom: 10px;
						font-size: 7.5vw;
						&::after {
							width: 220px;
							margin-left: -110px;
						}
					}
					.period {
						font-size: 5.1vw;
					}
					.price {
						font-size: 9.4vw;
						.yen {
							font-size: 6.4vw;
						}
					}
					.limited {
						font-size: 7vw;
					}
					.att {
						margin-top: 15px;
						font-size: 4vw;
					}
				}
			}
			.courseSec {
				margin-top: 85px;
				h3 {
					padding: 0 0 7px;
					font-size: 2.6rem;
				}
				section {
					margin-top: 10px;
					padding-top: 15px;
					&::before {
						width: 170px;
						height: 2px;
					}
					& + section {
						margin-top: 50px;
					}
					h4 {
						font-size: 1.8rem;
					}
					.flexBox {
						display: block;
						margin-top: 10px;
						.imgBox {
							width: auto;
							picture {
								width: 100%;
							}
						}
						.spTable {
							width: 100%;
							border-collapse: collapse;
							th, td {
								border: 2px solid #0098df;
								font-weight: bold;
								text-align: center;
								vertical-align: middle;
							}
							th {
								padding: 8px 0;
								background: #e5e5e5;
								border-bottom: none;
								font-size: 1.4rem;
								&:first-child {
									width: 36%;
								}
							}
							td {
								padding: 20px 0;
								background: #fff;
								font-size: 1.5rem;
								.price {
									font-size: 2.1rem;
								}
								.yen {
									font-size: 1.6rem;
								}
							}
							tbody {
								tr:nth-child(1) {
									td {
										border-top: none;
									}
								}
							}
						}
					}
				}
				.attList {
					margin-top: 25px;
					li {
						font-size: 1.2rem;
						line-height: 2.0;
					}
				}
				.cardBox {
					margin-top: 20px;
				}
			}
			.contactBtn {
				margin-top: 45px;
				padding: 0;
				a {
					&::after {
						width: 72.5px;
						height: 108px;
						background-image: url(../img/icon_price01_sp.png);
						background-size: 72.5px 108px;
						left: 20px;
					}
				}
			}
		}
		.clinicSec {
			padding: 130px 0 50px;
			background:
				url(../img/bg_clinic03_sp.jpg) no-repeat left 66%,
				linear-gradient(to bottom, #222, #222 50%, #f7f7f7 50%, #f7f7f7 100%);
			background-size: contain;
			&::before {
				width: 300px;
				height: 92px;
				background-size: 300px 92px;
			}
			&::after {
				content: none;
			}
			.cmnTtl01 {
				img {
					width: 118px;
				}
			}
			.ttlBox {
				height: 225px;
				margin-top: 45px;
				background-image: url(../img/bg_clinic01_sp.png);
				img {
					width: 222px;
				}
			}
			.flexBox {
				display: block;
				padding: 0 20px;
				position: relative;
				&::before {
					content: "";
					display: block;
					width: 100%;
					height: 125vw;
					background: url(../img/bg_clinic02_sp.png) no-repeat center top;
					background-size: contain;
					position: absolute;
					top: 58vw;
					left: 0;
				}
				.textBox {
					width: auto;
					padding-top: 0;
					dl {
						dt {
							padding-bottom: 7px;
							border-width: 1px;
							font-size: 2.6rem;
						}
						dd {
							padding: 20px 0 25px;
							font-size: 1.4rem;
							line-height: 1.6;
						}
					}
				}
				.mapBox {
					width: auto;
					iframe {
						width: 100%;
						height: 260px;
					}
				}
			}
			.messageBox {
				margin-top: 30.7vw;
				position: relative;
				&::before {
					content: "";
					display: block;
					width: 100%;
					height: 125vw;
					background: url(../img/bg_clinic02_sp.png) no-repeat center top;
					background-size: contain;
					position: absolute;
					top: 50vw;
					left: 0;
				}
				.messageWrap {
					position: relative;
					z-index: 10;
				}
				p {
					font-size: 4.3vw;
					line-height: 2.1;
					text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5), -10px -10px 20px rgba(0, 0, 0, 0.5);
					& + p {
						margin-top: 30px;
					}
				}
			}
			.logo {
				margin-top: -40px;
				img {
					width: 305px;
				}
			}
			.contactBtn {
				margin-top: 10px;
				.imgBox {
					padding-left: 15px;
					img {
						width: 293px;
					}
				}
			}
		}
	}
}
//////////////////////////////////////////////////
// Base Setting
//////////////////////////////////////////////////
body,button,h1,h2,h3,h4,h5,h6,input,option,select,table,textarea {
	color: $font_color;
	font-family: "Montserrat","游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
	line-height: 1.1;
}

body {
	font-size: $font_size;
}

a {
	&:link, &:visited {
		color: #000;
		text-decoration: underline;
	}

	&:hover, &:active {
		color: #000;
		text-decoration: none;
	}
}

.clearfix:after {
	content: "";
	display: block;
	clear: both;
}

@include mq('for-pc') {
	.sp-only {
		display: none !important;
	}
}
@include mq('for-sp') {
	.pc-only {
		display: none !important;
	}
}
